import { Injectable, OnInit } from '@angular/core';
import { User } from '../_models/user';
import { PT } from '../_models/pt';
import { HttpClient } from '@angular/common/http';
import { jwtDecode } from "jwt-decode"
import RegisterRequest from '../_models/auth/register-request';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url = "/api/authenticate";
  tittle: string;
  titleName: string;

  constructor(private http: HttpClient) {
    if (localStorage.getItem("token") && (!localStorage.getItem("expired") || new Date(localStorage.getItem('expires')) > new Date())) {
      this.isLogging = true;
      this.tittle = this.findRole();
      this.titleName = this.findUserName();
    }
    if (localStorage.getItem("user")) {
      let player: User = JSON.parse(localStorage.getItem("user"));
      if (player.lastName == "" && player.firstName == "") {
        this.logout();
      }
    }
  }

  isLogging: boolean = false;

  isLogged(): boolean {
    return this.isLogging;
  }

  findRole(): string {
    if (localStorage.getItem("user")) {
      let user: User = JSON.parse(localStorage.getItem("user"));
      return user.series + " " + user.number;
    }
    if (localStorage.getItem("pt")) {
      let pt: PT = JSON.parse(localStorage.getItem("pt"));
      return pt.name;
    }
    return "chief-admin";
  }

  findUserName(): string {
    if (localStorage.getItem("user")) {
      let user: User = JSON.parse(localStorage.getItem("user"));
      return user.lastName + " " + user.firstName;
    }
    if (localStorage.getItem("pt")) {
      let pt: PT = JSON.parse(localStorage.getItem("pt"));
      return pt.code.toString();
    }
    return "chief-admin";
  }

  parseToken(): any {
    let accessToken = localStorage.getItem("token");
    if (accessToken == null) {
      return null;
    }
    let user;
    try {
      user = jwtDecode(accessToken);
    } catch {
      return null;
    }
    return user;
  }

  getUserRole(): string {
    let tokenObj = this.parseToken();
    if (!tokenObj) {
      return null;
    }
    let role = tokenObj["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    return role
  }

  getUserId() {
    let tokenObj = this.parseToken();
    if (!tokenObj) {
      return null;
    }
    let role = tokenObj["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
  }

  loginChiefAdmin(password: string) {
    return this.http.post(this.url + '/chief-admin', { password: password });
  }

  loginUser(req: { login: string, password: string }) {
    return this.http.post(this.url + '/user', req);
  }

  loginConfirmUser(user: User) {
    return this.http.post(this.url + '/loginAfterConfirm', user);
  }

  registerUser(user: RegisterRequest) {
    //user.telephone = user.telephone.internationalNumber;
    return this.http.post(this.url + '/registration', user);
  }

  loginAdmin(pt: PT) {
    return this.http.post(this.url + '/admin', pt);
  }

  partialRegisterUser(user: any) {
    user.telephone = user.telephone.internationalNumber;
    return this.http.post(this.url + '/partialRegistrationUser', user);
  }


  logout() {
    localStorage.removeItem('chief-admin');
    localStorage.removeItem('pt');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.tittle = null;
    this.titleName = null;
    this.isLogging = false;
  }
}
